import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Images
import img1 from "../../assets/images/asset-2.gif";

class Section2 extends Component {
	render() {
		return (
			<React.Fragment>
				{/* FEATURES START  */}
				<section className="section bg-light position-relative pt-2 pb-2 pt-lg-5 pb-lg-5" id="features">
					<Container>

						<Row className="align-items-center">
							<Col lg={6}>
								<div className="features-img mt-4">
									<img
										src={img1}
										className="img-fluid mx-auto d-block"
									/>
								</div>
							</Col>

							<Col lg={6}>
								<div className="pr-lg-6 mt-4 ml-lg-5">
									<h3 className="line-height-1_6 text-dark">
										<strong>
											We give you the tools to deliver resilient health services
										</strong>
									</h3>
									<p className="text-muted f-15 text-justify">
										Healthics delivers an API-first core-healthcare solution offered to licensed and
										certified vendors. The modular structure allows for complete control over which
										services are integrated, creating a unique solution. You can start your business
										with one of the modules and deploy more over time in line with business
										expansion. This gives you a quicker time to market, keeping the costs controlled
										at a reasonable level, and allows for the ability to expand as and when the
										business requires.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Section2;
