import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Images
import img1 from "../../assets/images/asset-1.gif";

class Section1 extends Component {
	render() {
		return (
			<React.Fragment>
				{/* FEATURES START  */}
				<section className="section bg-light position-relative pt-2 pb-2 pt-lg-5 pb-lg-5" id="features">
					<Container>

						<Row className="align-items-center">

							<Col lg={6} className="order-lg-12">
								<div className="features-img">
									<img
										src={img1}
										className="img-fluid mx-auto d-block"
									/>
								</div>
							</Col>

							<Col lg={6} className="order-lg-1">
								<div className="pr-lg-6 mr-lg-5">
									<h3 className="line-height-1_6 text-dark mb-4">
										<strong>
											Modular platform that saves you time and resources
										</strong>
									</h3>
									<p className="text-muted mb-4 f-15 text-justify">
										Healthics provides a robust set of API endpoints that allow the integration with
										our modules via secure REST API calls.
									</p>
									<p className="text-muted mb-4 f-15 text-justify">
										Our API allows automating your company workflows with Healthics’ modules such
										as:
										<ul>
											<li>Medical &and; Healthcare</li>
											<li>Wellness &and; Fitness</li>
											<li>Insurance Services</li>
											<li>Security &and; GDPR Compliance</li>
										</ul>
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Section1;
