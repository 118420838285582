import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Images
import img1 from "../../assets/images/service-1.png";
import img2 from "../../assets/images/service-2.png";
import img3 from "../../assets/images/service-3.png";
import img4 from "../../assets/images/service-4.png";

class Section3 extends Component {
	render() {
		return (
			<React.Fragment>
				{/* FEATURES START  */}
				<section className="section bg-light position-relative pt-2 pb-2 pt-lg-5 pb-lg-5" id="features">
					<Container>
						<Row>
							<Col lg={8} className={"mb-4"}>
								<h2>Explore and integrate Healthics’ API to reduce R&D costs and time to market</h2>
							</Col>
						</Row>
						<Row>
							<Col lg={3}>
								<div>
									<div className="features-img">
										<img
											src={img1}
											className="img-fluid d-block"
										/>
									</div>
									<div className={"mt-3 mb-3"}>
										<h4>
											1. Sandbox
										</h4>
									</div>
									<div className={"mt-4 mb-5"}>
										The platform provides access to a secure testing environment with de-identified
										medical, wellness and other relevant data sources.
									</div>
								</div>
							</Col>
							<Col lg={3}>
								<div>
									<div className="features-img">
										<img
											src={img2}
											className="img-fluid d-block"
										/>
									</div>
									<div className={"mt-3 mb-3"}>
										<h4>
											2. Develop
										</h4>
									</div>
									<div className={"mt-4 mb-5"}>
										Build your product with our core modules with full cyber security and GDPR
										compliance. Save time and money while we handle the infrastructure
									</div>
								</div>
							</Col>
							<Col lg={3}>
								<div>
									<div className="features-img">
										<img
											src={img3}
											className="img-fluid d-block"
										/>
									</div>
									<div className={"mt-3 mb-3"}>
										<h4>
											3. Deploy
										</h4>
									</div>
									<div className={"mt-4 mb-5"}>
										You finished developing or you already have a product or a service? You can
										deploy it straight to your customers or list it on the marketplace.
									</div>
								</div>
							</Col>
							<Col lg={3}>
								<div>
									<div className="features-img">
										<img
											src={img4}
											className="img-fluid d-block"
										/>
									</div>
									<div className={"mt-3 mb-3"}>
										<h4>
											4. Service
										</h4>
									</div>
									<div className={"mt-4 mb-5"}>
										Manage and control all your services on the marketplace. Expand your customer
										reach or deploy new lines of products. Start Today.
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Section3;
