import React, { Component } from "react";

import { Container, Row, Col } from "reactstrap";

//Import Image
import heroBgImg from "./assets/images/main-bg.png";

class Section extends Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
		};
		this.callModal.bind(this);
	}

	callModal = () => {
		this.refs.child.openModal();
	};

	render() {
		return (
			<React.Fragment>
				{/* HERO START */}

				<section
					className="hero-3-bg position-relative"
					style={{background: `url(${heroBgImg}) top center no-repeat`, backgroundSize:"cover", height: "100vh"}}
				>
					<div/>
					<Container>
						<Row className=" justify-content-center">
							<Col lg={8}>
								<div className="text-center">
									<h1 className="text-white hero-3-title mb-4 line-height-1_4">
										Build and deploy healthcare products and services within days
									</h1>
								</div>
							</Col>
						</Row>
						<Row className="justify-content-center">
							<Col md={3} xs={12} className={"mb-3"}>
								<div className="text-center Subcribe-form">
									<form action="#">
										<button
											type="button"
											className="btn rounded-pill btn-white text-black m-0 p-2 w-100"
										>
											Start Today
										</button>
									</form>
								</div>
							</Col>
							<Col md={3} xs={12}>
								<div className="text-center Subcribe-form">
									<form action="#">

										<button
											type="button"
											className="btn rounded-pill btn-outline-white m-0 p-2 w-100"
										>
											Explore Sandbox
										</button>
									</form>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				{/* HERO END	*/}
			</React.Fragment>
		);
	}
}

export default Section;
