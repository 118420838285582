import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class Section6 extends Component {
	render() {
		return (
			<React.Fragment>
				{/* FEATURES START  */}
				<section className="section bg-light position-relative pt-2 pb-2 pt-lg-5 pb-lg-5" id="features">
					<Container>

						<Row className="align-items-center">
							<Col lg={6}>
								<div className="features-img mt-4 text-white p-4" style={{backgroundColor: "#244061", color: "#f5f5f5"}}>
									<h1 className={"pb-3"}>
										API docs
									</h1>

									<p className="f-16 text-justify pb-3">
										Find out how different APIs can be combined to create a seamless experience for your customers.The finest detail of every API endpoint revealed and explained.
									</p>
									<form action="#">

										<button
											type="button"
											className="btn rounded-pill btn-white text-black"
										>
											API Reference
										</button>
									</form>
								</div>
							</Col>

							<Col lg={6}>
								<div className="features-img mt-4 p-4" style={{backgroundColor: "#f5f5f5", color:"#244061"}}>
									<h1 className={"pb-3"}>
										Contact Us
									</h1>

									<p className="f-16 text-justify pb-3">
										Do you wish to learn more about our next-generation banking solution? Contact us today to discuss how Modularbank can benefit your business.
									</p>
									<form action="#">

										<button
											type="button"
											className="btn rounded-pill btn-darkblue text-white"
										>
											Contact
										</button>
									</form>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Section6;
