import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Images
import img1 from "../../assets/images/asset-3.png";

import gdprImg from "../../assets/images/gdpr.png";
import cyberSecurityImg from "../../assets/images/cyber-security.png";
import locationImg from "../../assets/images/location.png";

class Section1 extends Component {
	render() {
		return (
			<React.Fragment>
				{/* FEATURES START  */}
				<section className="section bg-light position-relative pt-2 pb-2 pt-lg-5 pb-lg-5" id="features">
					<Container>

						<Row className="align-items-center">

							<Col lg={6} className={"h-100"}>
								<div className="pr-lg-6 mr-lg-6">
									<h3 className="line-height-1_6 text-dark mb-4">
										<strong>
											Take advantage of our secure and compliant IT infrastructure
										</strong>
									</h3>
									<Container>
										<Row>
											<Col>
												<p className="text-muted">
													GDPR Compliant
												</p>

												<p className="text-muted f-15 text-left">
													We take data privacy seriously and enable citizens to manage who
													they share their data with
												</p>
											</Col>
											<Col>
												<p className="text-muted">
													Cyber Security
												</p>

												<p className="text-muted f-15 text-left">
													The Estonian approach to cyber security ensures the highest
													standards of cryptography
												</p>
											</Col>
											<Col>
												<p className="text-muted">
													EU Cloud Servers
												</p>

												<p className="text-muted f-15 text-left">
													Our cloud servers are based in Frankfurt, Germany and Dublin,
													Ireland, both in EU member states
												</p>
											</Col>
										</Row>
									</Container>
								</div>
							</Col>

							<Col lg={6}>
								<img
									src={img1}
									className="img-fluid mx-auto d-block"
								/>
							</Col>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Section1;
