import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Images
import img1 from "../../assets/images/asset-4.png";

class Section2 extends Component {
	render() {
		return (
			<React.Fragment>
				{/* FEATURES START  */}
				<section className="section bg-light position-relative pt-2 pb-2 pt-lg-5 pb-lg-5" id="features">
					<Container>

						<Row className="align-items-center">
							<Col lg={6}>
								<div className="features-img mt-4">
									<img
										src={img1}
										className="img-fluid mx-auto d-block"
									/>
								</div>
							</Col>

							<Col lg={6}>
								<div className="pr-lg-6 mt-4 ml-lg-5">
									<h3 className="line-height-1_6 text-dark">
										<strong>
											Deploy medical, wellness and insurance services in real time
										</strong>
									</h3>
									<p className="text-muted f-15 text-justify">
										Healthics enables you to deploy small or large scale programs and reach your
										customers directly on their smartphone or wearable device. Simply choose your
										license and product category (digital or physical) and start today!
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Section2;
